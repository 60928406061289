import React, { useCallback, useMemo, useState } from 'react'
import forIn from 'lodash/forIn'
import { useForm } from 'react-hook-form'
import Modal from '@c/modal'

import FormInput from '@c/form-input'
import FormFile from '@c/form-file'

const Form = ({
  title,
  firstNameLabel,
  lastNameLabel,
  eMailAddressLabel,
  workingTypes,
  selectCvLabel,
  sendButtonText,
  successMessage,
  failMessage,
  postUrl,

  openPosition
}) => {
  const form = useForm()
  const [state, setState] = useState(() => ({
    modalContent: '',
    modalToggle: 'd-none'
  }))

  const onSend = useCallback(() => {
    const url = process.env.GATSBY_CAREER_APPLY_FORM_POST_URL
    const formData = new FormData()

    formData.append('Position', openPosition.title)
    formData.append('Position Description', openPosition.description)
    forIn(form.getValues(), (value, key) => {
      if (key === 'cv') {
        formData.append('cv', value[0])
      } else {
        formData.append(key, value)
      }
    })
    setState({ modalToggle: 'modal', modalContent: 'Please Waiting..' })

    fetch(url, {
      method: 'post',
      body: formData
    })
      .then(async response => ({ response, body: await response.text() }))
      .then(() => {
        setState({ modalToggle: 'd-none' })
      })
      .then(() => {
        form.reset()
        setState({ modalToggle: 'modal', modalContent: successMessage })
      })
      .catch(() => {
        setState({ modalToggle: 'modal', modalContent: failMessage })
      })
      .finally(() => {
        setTimeout(() => {
          setState({ modalToggle: 'd-none' })
        }, 2000)
      })
  }, [form, postUrl, openPosition, successMessage, failMessage])
  const workingTypesArr = useMemo(() => JSON.parse(workingTypes), [
    workingTypes
  ])

  return (
    <div>
      <form className="bg-soft-blue p-32" onSubmit={form.handleSubmit(onSend)}>
        <h4>{title}</h4>

        <div className="mt-24">
          <FormInput
            name="name"
            placeholder={firstNameLabel}
            ref={form.register()}
            required={true}
          />
        </div>

        <div className="mt-24">
          <FormInput
            name="surname"
            placeholder={lastNameLabel}
            ref={form.register()}
            required={true}
          />
        </div>

        <div className="mt-24">
          <FormInput
            type="email"
            name="email"
            placeholder={eMailAddressLabel}
            ref={form.register()}
            required={true}
          />
        </div>

        <div className="form-select form--required mt-24">
          <select name="working-types" ref={form.register()}>
            {workingTypesArr.map(type => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>

        <div className="mt-24">
          <FormFile
            name="cv"
            ref={form.register()}
            accept=".pdf,.doc,.docx"
            required={true}
          >
            {selectCvLabel}
          </FormFile>
        </div>

        <button type="submit" className="btn btn--dark mt-24">
          {sendButtonText}
        </button>
      </form>
      <Modal
        modalToggle={state.modalToggle}
        modalContent={state.modalContent}
      />
    </div>
  )
}

export default Form
