import React from 'react'
import { graphql } from 'gatsby'

import Layout from '@c/layout'
import Breadcrumb from '@c/breadcrumb'

import DatoCMSModel from '@m/DatoCMSModel'

import Content from '@v/culture-detail/content'
import Form from '@v/culture-detail/form'
import makeSlug from '@u/make-slug'

const Page = ({ data }) => {
  const { culturePage, openPosition } = data
  const { title } = openPosition
  const parentPages = [
    {
      title: culturePage.title,
      url: makeSlug.generic(culturePage.locale, culturePage.slug)
    }
  ]
  const model = new DatoCMSModel(openPosition)

  return (
    <Layout
      locale={openPosition.locale}
      seo={{ ...model.getSeoComponentProps(culturePage._allSlugLocales) }}
    >
      {/* BREADCRUMB */}
      <div className="container mb-60">
        <div className="row">
          <div className="col-12">
            <Breadcrumb
              parentPages={parentPages}
              currentPage={title}
              wrapCurrentPageWithH1={true}
            />
          </div>
        </div>
      </div>

      {/* BODY */}
      <div className="container">
        <div className="row">
          {/* CONTENT */}
          <div className="col-12 col-lg-7">
            <Content description={openPosition.description} />
          </div>

          {/* FORM */}
          <div className="col-12 offset-lg-1 col-lg-4 mt-60 mt-lg-0">
            <Form
              openPosition={openPosition}
              {...culturePage.applicationForm[0]}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page

export const query = graphql`
  query OpenPositionDetailPage($id: String, $locale: String) {
    culturePage: datoCmsCulturePage(locale: { eq: $locale }) {
      _allSlugLocales {
        locale
        value
      }
      locale
      slug
      title

      applicationForm {
        title
        firstNameLabel
        lastNameLabel
        eMailAddressLabel
        workingTypes
        selectCvLabel
        sendButtonText
        successMessage
        failMessage
        postUrl
      }
    }

    openPosition: datoCmsOpenPosition(id: { eq: $id }) {
      _allSlugLocales {
        locale
        value
      }
      metaTags {
        ...MetaTags
      }
      locale
      title
      description
    }
  }
`
