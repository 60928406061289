import React from 'react'
import './content.css'
const Content = ({ description }) => {
  return (
    <div
      className="career-content"
      style={{ whiteSpace: 'pre-line' }}
      dangerouslySetInnerHTML={{ __html: description }}
    ></div>
  )
}

export default Content
